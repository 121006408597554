@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import "assets/css/fontawesome.min.css";
@import "assets/css/common.css";
@import "assets/css/form-element.css";
@import "assets/css/header.css";
@import "assets/css/footer.css";
@import "assets/css/pre-login-page.css";
@import "assets/css/profile.css";
@import "assets/css/edit-profile.css";
@import "assets/css/accounts.css";
/* general style */

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

html,
body {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 700;
}

.transition {
  transition: all 0.3s;
}

.cursor-pointer {
  cursor: pointer;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
