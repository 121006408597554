.inner-content {
  padding: 25px 20px;
}

.document {
  flex-wrap: nowrap;
}

.tab-card,
.tab-card a {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 185px;
  width: 100%;
}

.tab-card:hover,
.tab-card a:hover {
  background-color: #e8f0fe;
}

.active-tab,
.active-tab a {
  border: 1px solid #073d83;
  overflow: hidden;
  border-radius: 5px;
}

.active-tab a i,
.active-tab a p {
  color: #073d83;
}

.tab-card:first-child {
  margin-left: 0 !important;
}

.tab-card:last-child {
  margin-right: 0 !important;
}

.form-control.is-invalid.document-type{
  background-image: none !important;
}

.document-tab {
  font-size: 30px;
}

.account-button {
  position: fixed;
  bottom: 60px;
  border-top: 1px solid #dadce0;
  padding: 15px 0 0;
  /* padding: 0 .75rem;
  left: 0; */
}

.action-btn {
  width: 120px;
  height: 50px;
}

.action-btn.prv-button:hover {
  background-color: #073d83 !important;
  color: white;
  /* cursor: pointer; */
}

.upload-file {
  height: 225px;
  border-style: dashed;
  position: relative;
  cursor: pointer;
}

.upload-file i {
  font-size: 100px;
  margin-top: 30px;
}

.upload-file p {
  padding: 10px;
  border-top: 1px solid #dadce0;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* document upload */
.document-image {
  height: 158px;
  width: auto;
  max-width: 100%;
}

.pointer-events-none {
  pointer-events: none !important;
}

/* personalInformation.jsx */
/* hide border seprator*/
.select-country .css-1okebmr-indicatorSeparator {
  display: none;
}

/* country select module css design */
@media (min-width: 768px) {
  .custom-country-select .col-sm-5 {
    flex: 0 0 auto;
    width: 47.666667%;
  }

  .custom-country-select .col-sm-2 {
    flex: 0 0 auto;
    width: 4.666667%;
  }
}

.select-country.is-invalid>div {
  border-color: #dc3545 !important;
}

/* gender */
.bg-none {
  background-image: none !important;
}

/* webcame modal */
.webCam-modal .modal-footer {
  border-top: none;
}

.webcame {
  padding: 0;
}

.webcame video {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .document {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .tab-card {
    margin: 5px 0 !important;
  }

  .account-button {
    width: 90%;
  }

  .upload-file {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {

  .tab-card,
  .tab-card a {
    min-width: 140px;
  }

  .account-gender i {
    display: none;
  }

  .action-btn {
    width: 80px;
    height: 40px;
  }

  .account-button {
    padding: 10px 0.75rem 0;
    left: 0;
    width: 100%;
  }

  .document {
    justify-content: space-around;
  }
}

@media screen and (max-width: 360px) {
  .inner-content {
    padding: 15px 10px;
  }

  .tab-card,
  .tab-card a {
    min-width: 130px;
  }
}