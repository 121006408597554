/* hide badge */
html body .reactour_touristGuide {
  max-width: 620px;
  width: 95%;
  padding: 13px 30px;
  /* top: -243px; */
}

.reactour_touristGuide div[data-tour-elem="controls"] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reactour_touristGuide button[data-tour-elem="left-arrow"],
.reactour_touristGuide button[data-tour-elem="right-arrow"] {
  color: #073d83;
}

@media (max-width: 1199px) {
    .reactour__mask--disable-interaction {
        background-color: #830707;
        color: red;
    }

    .reactour_touristGuide {
        /* top: -225px; */
    }
  }


@media (max-width: 575px) {
  .reactour_touristGuide {
    padding: 13px 30px;
    top: -243px;
  }
}
