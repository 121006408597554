.edit-profile {
    position: relative;
    margin: auto;
}

.edit-profile .personal-info {
    padding: 0;
}

.edit-profile .back-button {
    background: transparent;
    border: none;
    width: 60px;
    height: 60px;
    justify-content: flex-start;
    position: absolute;
    left: -80px;
}

.edit-crop-modal {
    max-width: 700px !important;
}

.modal-crop-img-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.modal-crop-img-div {
    display: flex;
    border-radius: 50%;
    width: 230px;
    height: 230px;
    overflow: hidden;
}

.crop-modal-section {
    display: flex;
    border-right: 1px solid gray;
    overflow: hidden;
}

.min-h-150 {
    min-height: 150px;
}

.edit-profile .back-button:hover {
    background-color: #e8f0fe;
    border-radius: 50%;
}

.edit-profile .basic-info {
    min-height: 432px;
}

.edit-profile .info-title {
    padding: 25px 20px 20px;
}

.edit-profile .info-field {
    padding: 10px 20px;
    min-height: 60px;
}

.edit-profile .info-field .info-name {
    min-width: 210px;
    text-transform: uppercase;
}

.edit-profile .info-field .info-image .photo {
    width: 60px;
    height: 60px;
    /* background-color: #073D83; */
    background-color: #c5c5c5;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
}

.edit-profile .info-field .info-image {
    overflow: hidden;
}

.edit-profile .info-field .info-text {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.edit-profile .info-field .info-detail {
    margin-left: auto;
}

.contact-info {
    min-height: 215px;
    overflow: hidden;
}

.select-form {
    margin-right: 10px;
    border-radius: 0;
    min-width: 88px;
    width: auto;
}

.z-index {
    z-index: 1 !important;
}

.mobile-no .MuiInput-root::before,
.MuiInput-root::after {
    display: none !important;
}

.mobile-no .MuiInputBase-input {
    font-size: 16px;
    line-height: 26px;
    color: #4c4f53;
    font-family: "Lato";
    font-weight: bold;
}

.fw-bold {
    font-weight: 600 !important;
}

@media screen and (max-width: 767px) {
    .edit-profile .info-field .info-name {
        min-width: 140px;
    }

    .edit-profile .info-field .info-detail {
        margin-left: initial;
    }

    .edit-profile .info-field .info-image .photo {
        margin-right: 10px;
    }

    .edit-profile .personal-info {
        padding: 0 0 0 40px;
    }

    .edit-profile .back-button {
        left: 0;
    }
}

.button-modal {
    background-color: #143d83 !important;
    color: #fff;
    border-color: #143d83 !important;
}

.button-modal:hover {
    background-color: #143d83 !important;
    color: #fff;
    border-color: #143d83 !important;
}

.button-cancel {
    border-color: #143d83 !important;
    color: #143d83;
    background-color: #fff;
}

.button-cancel:hover {
    background-color: #143d83 !important;
    color: white !important;
}

.footer-modal {
    display: flex;
    justify-content: space-between;
    width: 100%;
}