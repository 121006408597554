.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #143d83;
}

input:focus+.slider {
  box-shadow: 0 0 1px #143d83;
}

.link-resend {
  color: #143d83;
  font-weight: 700;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.security-box:hover {
  background-color: #fafafaee;
}

.button-hover:hover {
  background-color: #143d83;
  color: white !important;
}

.ellipses {
  position: absolute;
  right: 0;
  top: 0;
}

.ellipses .dropdown-menu {
  min-width: auto;
}

.device-icon {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.device-login {
  width: calc(100% - 50px);
}

.otp-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(16.66% - 12.5px);
  flex-direction: 0 0 calc(16.66% - 12.5px);
  max-width: calc(16.66% - 12.5px);
  margin-right: 15px;
  color: #dadce0;
}

.otp-div:nth-child(6n) {
  margin-right: 0;
}

.otp-div input {
  height: 40px;
  border-radius: 6px;
  border: 1px solid #dadce0;
  color: gray;
  width: 100% !important;
}

.mobile-security {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #dadce0 !important;
  color: gray;
  padding: 10px 20px 10px 0 !important;
}

.mobile-security .MuiInputBase-input {
  padding: 0 !important;
  height: auto;
  font-size: 14px;
  line-height: 22px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.modal-button {
  background-color: #143d83;
  color: #fff;
}

.modal-button :hover {
  background-color: #143d83 !important;
  color: white;
}

.margin-left {
  margin-left: 10px;
}

.button-modal {
  background-color: #143d83;
  color: #fff;
}

.button-modal:hover {
  background-color: #143d83;
  color: #fff;
}

.modal-footer-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-cancel {
  border-color: #143d83;
  color: #143d83;
}

.footer-modal {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.box-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
}

.icon-wh {
  width: 10px;
  height: 10px;
}

.password-box {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
}

.security-box-verify:hover {
  background-color: #fafafaee;
}

.box-container-verify {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.info-app-section p {
  color: hsl(214deg 4% 31%);
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

@media (max-width: 1399px) {
  .main-form-section {
    min-height: 500px !important;
  }

  .title {
    font-size: 22px;
  }

  .info-image- {
    height: 330px !important;
  }
}

@media (max-width: 1199px) {
  .main-form-section {
    min-height: 500px;
  }

  .title {
    font-size: 22px;
  }

  .info-image- {
    height: 330px !important;
  }
}

@media (max-width: 991px) {
  .main-form-section {
    min-height: 500px;
  }

  .title {
    font-size: 22px;
  }

  .info-image- {
    height: 330px !important;
  }
}

@media (max-width: 575px) {
  .main-form-section {
    min-height: 500px;
  }

  .title {
    font-size: 22px;
  }

  .otp-div {
    width: calc(16.66% - 8.33px);
    max-width: calc(16.66% - 8.33px);
    flex: 0 0 calc(16.66% - 8.33px);
    margin-right: 10px;
  }

  #react-qrcode-logo {
    width: 250px !important;
    height: 250px !important;
  }

  .main-form-section {
    position: relative;
  }

  .info-image- {
    position: absolute;
    left: auto;
    right: auto;
    width: 350px;
    height: 272px !important;
    top: 25%;
  }

  .form-bottm-secton {
    position: absolute;
    bottom: 15px;
  }
}

.info-app-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
}

.info-app-section p {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: hsl(214deg 4% 31%);
}

.info-image- {
  margin: 10px 0;
  background: transparent url("../images/qrCodeScanImg.png") 0% 0% no-repeat padding-box;
  width: 350px;
  height: 380px;
}

.link-qr-code {
  text-align: center;
  font: normal normal 600 14px/17px Lato;
  letter-spacing: 0px;
  color: #073d83;
  cursor: pointer;
}

.display-none {
  display: none;
}

.profile-img-custom {
  width: 100%;
  height: 100%;
}

.c-m-0 {
  margin: 0
}

.or-text-color {
  color: #DADCE0;
}

.genderSec {
  display: flex;
}

.changePassTxt {
  display: none;
}

.min-width-info {
  min-width: 210px;
}

@media screen and (max-width: 767px) {
  .tapToChangePassText {
      display: none;
  }

  .changePassTxt {
      display: block;
  }

  .passwordTxt {
      display: none;
  }

  .edit-profile .info-field {
      padding: 10px 0px 10px 20px;
  }

  #twoStepVerificationSec,
  #googleAuth {
      padding: 10px 16px 10px 20px;
  }

  .signInDeviceSec {
      width: 270px;
      justify-content: space-between;
  }
}

@media screen and (max-width: 575px) {
  .edit-profile .info-field .info-name {
      min-width: 155px;
  }

  .min-width-info {
      min-width: 85px;
  }

  .edit-profile .info-field {
      padding: 10px 20px 10px 20px;
  }

  .verifiedTxt {
      display: none;
  }

  .birthDaySelectionOptions {
      margin-left: 6%;
  }

  .genderTitle {
      margin-bottom: 3%;
  }

  .genderSec {
      display: block;
  }
}