.footer{
    background-color: #fff;
    position: fixed;
    bottom: 0;
    padding: 20px;
    height: 60px;
    z-index: 2;
}
.footer a{
    margin-right: 20px;
}
.footer a:last-child{
    margin-right: 0;
}
.footer p:hover{
    color: #0a58ca;
}


@media screen and (max-width: 767px) {
    .footer{
        padding: 10px;
        /* justify-content: center !important; */
    }
    .footer a{
        margin-right: 10px;
    }
}